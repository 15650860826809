import { Button, Col, Form, Input, Row, Typography, Upload } from "antd";
import { useState } from "react";
import { sendMail } from "../services";

export default function Contact() {
  const [file, setFile] = useState();
  return (
    <>
      <div
        style={{
          width: 440,
          margin: "auto",
          paddingTop: "10vh",
          marginBottom: "10vh"
        }}
      >
        <Form layout="vertical" onFinish={(values) => sendMail(values, file)}>
          <Typography.Title style={{ marginBottom: 20 }} level={2}>
            Formulaire de contact
          </Typography.Title>
          <Row gutter={20}>
            <Col span={12}>
              <Form.Item label="Nom de famille" name="lastName" required>
                <Input type="text" required />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Prénom" name="firstName" required>
                <Input type="text" required />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Adresse mail" name="email" required>
            <Input type="email" required />
          </Form.Item>
          <Form.Item label="Téléphone" name="phone">
            <Input type="tel" />
          </Form.Item>
          <Row gutter={20}>
            <Col span={14}>
              <Form.Item label="Sujet" name="subject" required>
                <Input type="text" required />
              </Form.Item>
            </Col>
            <Col span={10}>
              <Form.Item label="N° de dossier" name="folder">
                <Input type="text" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Message" name="message" required>
            <Input.TextArea required />
          </Form.Item>
          <Button block type="primary" htmlType="submit">
            Envoyer
          </Button>
        </Form>
      </div>
    </>
  );
}
