import {
  Button,
  Col,
  Collapse,
  Divider,
  Drawer,
  Dropdown,
  Form,
  Input,
  List,
  message,
  Popover,
  Row,
  Select,
  Space,
  Typography
} from "antd";
import {
  FacebookOutlined,
  LinkedinOutlined,
  MailOutlined,
  MenuFoldOutlined,
  PhoneOutlined
} from "@ant-design/icons";
import { useState } from "react";
import { services } from "../data/services-d";

export default function Navigation(props) {
  const [mobileMenu, setMobileMenu] = useState(false);
  if (props.width < 800) {
    return (
      <>
        <Drawer
          headerStyle={{ backgroundColor: "#235a4b", color: "white" }}
          bodyStyle={{ backgroundColor: "#235a4b" }}
          title="Menu"
          placement="right"
          visible={mobileMenu}
          onClose={() => setMobileMenu(false)}
        >
          <List style={{ color: "white" }}>
            <List.Item>
              <a href="/" style={{ textDecoration: "none", color: "white" }}>
                Accueil
              </a>
            </List.Item>
            <List.Item>
              <a
                href="/about"
                style={{ textDecoration: "none", color: "white" }}
              >
                A propos
              </a>
            </List.Item>
            <List.Item>
              <a
                href="/constat"
                style={{ textDecoration: "none", color: "white" }}
              >
                Services
              </a>
            </List.Item>
            <List.Item>
              <a
                href="/contact"
                style={{ textDecoration: "none", color: "white" }}
              >
                Contact
              </a>
            </List.Item>
          </List>
        </Drawer>
        <div
          style={{
            backgroundColor: "#235a4b",
            padding: "20px 7%"
          }}
        >
          <Row>
            <Col span={12}>
              <Typography.Link
                href="/"
                style={{
                  color: "white",
                  fontFamily: "DM Serif Display",
                  fontSize: 22
                }}
              >
                SCP Bedry
              </Typography.Link>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                onClick={() => setMobileMenu(true)}
                icon={<MenuFoldOutlined />}
                style={{
                  border: "none",
                  borderRadius: 0,
                  backgroundColor: "#ecfeb7",
                  color: "black"
                }}
              />
            </Col>
          </Row>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div style={{ backgroundColor: "#ecfeb7", padding: "10px 7%" }}>
          <Row>
            <Col span={12}>
              <Space direction="horizontal" size={30}>
                <Button
                  type="text"
                  style={{ padding: 0, color: "black" }}
                  href="mailto:contact@scp-bedry.fr"
                >
                  <MailOutlined /> contact@huissierfonsorbes.fr
                </Button>
                <Button
                  type="text"
                  style={{ padding: 0, color: "black" }}
                  href="tel:+33561918853"
                >
                  <PhoneOutlined /> 05 61 91 88 53
                </Button>
              </Space>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Button
                href="https://www.linkedin.com/in/beno%C3%AEt-bedry-87458a159?miniProfileUrn=urn%3Ali%3Afs_miniProfile%3AACoAACX-nFgBtAQrmL7Fm9bFS7BJnfhHPv3Kkg4&lipi=urn%3Ali%3Apage%3Ad_flagship3_search_srp_all%3Byu58s7PeSVSsjrhhJXGTiA%3D%3D"
                icon={<LinkedinOutlined style={{ fontSize: 20 }} />}
                style={{ color: " black" }}
                type="text"
              />
              <Button
                icon={<FacebookOutlined style={{ fontSize: 20 }} />}
                style={{ color: " black" }}
                type="text"
              />
            </Col>
          </Row>
        </div>
        <div
          style={{
            backgroundColor: "#235a4b",
            padding: "20px 7%"
          }}
        >
          <Row>
            <Col span={12}>
              <Typography.Link
                href="/"
                style={{
                  color: "white",
                  fontSize: 22
                }}
              >
                SCP Bedry
              </Typography.Link>
            </Col>
            <Col span={12} style={{ textAlign: "right" }}>
              <Space direction="horizontal">
                <Button
                  id={this}
                  type="text"
                  style={{ color: "white" }}
                  href="/"
                >
                  Accueil
                </Button>
                <Button
                  id={this}
                  type="text"
                  style={{ color: "white" }}
                  href="/about"
                >
                  A propos
                </Button>

                <Popover
                  content={
                    <Space direction="vertical">
                      {services.map((s) => (
                        <Button
                          type="text"
                          style={{ textAlign: "left" }}
                          href={s.path}
                        >
                          {s.name}
                        </Button>
                      ))}
                    </Space>
                  }
                >
                  <Button id={this} type="text" style={{ color: "white" }}>
                    Services
                  </Button>
                </Popover>
                <Button
                  id={this}
                  type="text"
                  style={{
                    color: "black",
                    backgroundColor: "#ecfeb7",
                    borderRadius: 0,
                    width: 80
                  }}
                  href="/contact"
                >
                  Contact
                </Button>
              </Space>
            </Col>
          </Row>
        </div>
        <Drawer visible={false} width={600} title="Outils">
          <Collapse>
            <Collapse.Panel header="Recouvrement">
              <Form layout="vertical">
                <Typography style={{ fontSize: 20 }}>
                  Vos informations
                </Typography>
                <Divider />
                <Form.Item label="Vous êtes">
                  <Select>
                    <Select.Option>Société commerciale</Select.Option>
                  </Select>
                </Form.Item>
              </Form>
            </Collapse.Panel>
          </Collapse>
        </Drawer>
      </>
    );
  }
}
