import { Col, Divider, Image, Row, Typography } from "antd";

export default function About() {
  return (
    <>
      <div
        style={{
          padding: "40px 7%",
          backgroundColor: "#235a4b",
          color: "white"
        }}
      >
        <h2 style={{ color: "white" }}>A propos</h2>
      </div>
      <div
        style={{ padding: "60px 7%", textAlign: "justify", fontSize: "20px" }}
      >
        <Row gutter={[30, 60]}>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 2} xl={24 / 2}>
            <Typography.Title>Qui sommes nous ?</Typography.Title>
            <Typography>
              Le SCP BEDRY, étude d’Huissier de Justice située sur la commune de
              FONSORBES (31470) en HAUTE-GARONNE, fait preuve d’une polyvalence
              pour accompagner particuliers comme entreprises (constat,
              recouvrement, médiation, signification, etc.).{" "}
            </Typography>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 2} xl={24 / 2}></Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 2} xl={24 / 3}>
            <div className="cardAboutOne">
              <Typography.Title level={2}>Pourquoi nous ?</Typography.Title>
              <Divider />
              <Typography style={{ marginBottom: 60 }}>
                Notre proximité immédiate de TOULOUSE et de sa périphérie nous
                permet d’être au cœur du terrain. Le titulaire de l’office,
                Huissier de Justice depuis plus de 30 ans, transmet son
                expérience à une équipe de jeunes juristes motivés, dont une
                Commissaire de Justice stagiaire. Notre travail en équipe nous
                permet de vous faire bénéficier d’un accompagnement personnalisé
                pour pouvoir répondre au mieux à toutes vos demandes
                (consultation juridique, rédaction d’actes, etc.).
              </Typography>
              <Image
                src="https://photo-cdn2.icons8.com/Pofdw0qjHCElVJ30-_Bqrnr55dQ9A5wWZ5VOInWQJAk/rs:fit:835:1072/czM6Ly9pY29uczgu/bW9vc2UtcHJvZC5h/c3NldHMvYXNzZXRz/L2VkaXRvci9tb2Rl/bC84MjMvN2FjNWIy/ZmQtNTI5Ny00MzEy/LTgxMjYtYTNmMzg3/ZDU5ZjI3LnBuZw.png"
                preview={false}
                width={340}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 2} xl={24 / 3}>
            <div className="cardAboutTwo">
              <Typography.Title level={2}>Notre proximité</Typography.Title>
              <Divider />
              <Typography style={{ marginBottom: 60 }}>
                Pour les activités de recouvrement judicaire, d’exécution et de
                signification, nous sommes territorialement compétents sur le
                ressort de la Cour d’appel de TOULOUSE et s’étend sur quatre
                départements, à savoir l’Ariège (09), la Haute-Garonne (31), le
                Tarn (81) et le Tarn-et-Garonne (82). Pour les autres activités,
                tel que le constat et le recouvrement amiable, nous sommes
                compétents nationalement.
              </Typography>
              <Image
                style={{ top: -57, position: "absolute", left: -30 }}
                src="https://photo-cdn2.icons8.com/1SsXwXDVIlAMUhUEVdpffaT6p1ffZxpW7eC1Kz2b7Ws/rs:fit:1060:1072/czM6Ly9pY29uczgu/bW9vc2UtcHJvZC5h/c3NldHMvYXNzZXRz/L2VkaXRvci9tb2Rl/bC8zNTcvNjllYTY4/NDEtZmIwNC00Yzkw/LWJlZDUtZjZjNWFm/N2U0YjFjLnBuZw.png"
                preview={false}
                width={310}
              />
            </div>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 2} xl={24 / 3}>
            <div className="cardAboutTree">
              <Typography.Title level={2}>Un nouveau titre</Typography.Title>
              <Divider />
              <Typography style={{ marginBottom: 60 }}>
                La loi Croissance et activité du 6 août 2015 a donné naissance à
                une nouvelle profession : Commissaire de Justice, réunissant le
                métier d’Huissier de justice et celui de Commissaire-Priseur,
                qui rentrera en vigueur à compter du 1er juillet 2022.
              </Typography>
              <Image
                src="https://commissaire-justice.fr/wp-content/uploads/2019/02/LOGO_CNCJ_header-retina.png"
                preview={false}
                width="100%"
              />
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}
