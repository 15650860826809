import { Typography } from "antd";

export const services = [
  {
    image:
      "https://images.unsplash.com/photo-1530971013997-e06bb52a2372?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8MjB8fHJlcG9ydHxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    name: "Constat",
    content: (
      <Typography style={{ fontSize: 20, textAlign: "justify" }}>
        Le but du constat est de prouver des faits litigieux ou de se constituer
        une preuve avant tout litige. <br />
        L’intervention d’un huissier de justice ou d’un clerc habilité aux
        constats vous permettra de figer une situation de faits, constater par
        un tiers neutre et indépendant à cette situation. <br /> Nous pouvons
        intervenir tant auprès de particuliers que de professionnels pour
        effectuer des constats de malfaçons, des constats avant et après
        travaux, des constats d’abandon de chantier, des constats de voirie, des
        constats d’état des lieux d’entrée ou de sortie, des constats
        d’affichage d’autorisation d’urbanisme, des constats de conflits de
        voisinage, des constats d’inventaire, etc.
        <br /> Votre demande de constat peut se faire soit via notre site
        internet, via notre adresse mail ou en contactant téléphoniquement
        l’Etude.
        <br /> Nous nous engageons à traiter votre demande dans un délai maximal
        de 48 heures à compter de la réception de celle-ci.
        <br /> Le Procès-Verbal peut prendre la forme soit d’un constat écrit
        avec photographies soit d’un constat vidéo. <br />
        Le constat vous ait ensuite livré soit par voie postale soit par envoi
        de mail soit par remise en mains propre à l’Etude.
        <br /> Pour les constats, nous sommes compétents nationalement.
      </Typography>
    ),
    path: "/constat"
  },
  {
    name: "Consultation juridique",
    content: (
      <Typography style={{ fontSize: 20 }}>
        Notre expérience nous permet de répondre au mieux à toute vos demandes
        notamment lorsque celles-ci prennent la forme d’une consultation
        juridique. Nous pouvons vous proposer de nous déplacer à votre domicile
        afin de traiter vos questionnements juridiques dans le but d’y répondre
        de la manière la plus adaptée possible.
      </Typography>
    ),
    path: "/consultation-juridique"
  },
  {
    image:
      "https://images.unsplash.com/photo-1459499362902-55a20553e082?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8Y29uZmxpY3R8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
    name: "Médiation",
    content: (
      <Typography style={{ fontSize: 20 }}>
        Nous intervenons à ce titre en tant que tiers neutre et impartial le but
        étant de rapprocher les parties afin de trouver une solution convenant
        aux deux parties. Maître BEDRY est médiateur certifié par la plateforme
        MEDICYS. Pour la médiation, nous sommes compétents nationalement.
      </Typography>
    ),
    path: "/mediation"
  },
  {
    image:
      "https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8aW52b2ljZXxlbnwwfHwwfHw%3D&auto=format&fit=crop&w=800&q=60",
    name: "Recouvrement amiable et judiciaire",
    content: (
      <Typography style={{ fontSize: 20 }}>
        Sur le recouvrement amiable : le but étant d’obtenir le paiement d’une
        créance sans l’intervention d’une décision de justice. Le recouvrement
        amiable présente un intérêt d’autant plus important pour les créances de
        somme d’argent inférieures à 5 000 euros depuis la loi du n°2019-222 du
        23 mars 2019 et son décret d’application n°2019-1333 du 11 décembre
        2019. Ces textes ont imposé, à peine de voir rejeter votre demande par
        un Juge, d’avoir recours à un mode alternatif de règlement des
        différends pour ces créances-là. Pour le recouvrement amiable, nous
        sommes compétents nationalement. Sur le recouvrement judiciaire : si le
        recouvrement amiable n’a pas abouti favorablement, notre Etude peut vous
        accompagner pour obtenir une décision de justice condamnant votre
        débiteur au paiement des sommes dues (assignation, dépôt d’une requête
        en injonction payer). Notre statut nous permet de mettre en place des
        mesures d’exécution forcées afin de contraindre votre débiteur à
        régulariser son dû. Pour le recouvrement judiciaire, vous pouvez faire
        appel à notre Etude pour toute signification à effectuer à une personne
        domiciliée dans le ressort de la Cour d’appel de TOULOUSE qui s’étend
        sur quatre départements, à savoir l’Ariège (09), la Haute-Garonne (31),
        le Tarn (81) et le Tarn-et-Garonne (82).
      </Typography>
    ),
    path: "/recouvrement-amiable-et-juridique"
  },
  {
    image:
      "https://images.unsplash.com/photo-1603796846097-bee99e4a601f?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NXx8bGVnYWx8ZW58MHx8MHx8&auto=format&fit=crop&w=800&q=60",
    name: "Significations d'acte",
    content: (
      <Typography style={{ fontSize: 20 }}>
        La signification par Huissier de justice permet de notifier à toute
        personne un acte (décision de justice, assignation, lettre, etc.).
        L’intervention de l’Huissier permet ainsi que sécuriser votre
        signification afin d’éviter toute contestation ultérieure. Vous pouvez
        faire appel à notre Etude pour toute signification à effectuer à une
        personne domiciliée dans le ressort de la Cour d’appel de TOULOUSE qui
        s’étend sur quatre départements, à savoir l’Ariège (09), la
        Haute-Garonne (31), le Tarn (81) et le Tarn-et-Garonne (82).
      </Typography>
    ),
    path: "/signification-d-actes"
  },
  {
    name: "Rédaction d'actes",
    content: (
      <Typography style={{ fontSize: 20 }}>
        Nous pouvons vous accompagner pour la rédaction de vos actes sous
        seing-privé tels qu’un bail d’habitation ou encore un bail commercial.
        Notre expérience et notre polyvalence nous permettent d’établir les
        actes les plus précis possibles, le but étant d’éviter tout contentieux
        futur.
      </Typography>
    ),
    path: "/redaction-d-actes"
  }
];
