import { Col, Image, List, Row, Space, Typography } from "antd";
import Navigation from "../components/header";
import { services } from "../data/services-d";

export default function Services({ title, content, image }) {
  return (
    <>
      <div style={{ padding: "40px 7%", backgroundColor: "#235a4b" }}>
        <Typography.Title style={{ color: "white" }}>{title}</Typography.Title>
      </div>
      <div style={{ padding: "40px 7%" }}>
        <Row gutter={[40, 40]}>
          <Col xs={24} lg={7}>
            <List bordered>
              {services.map((s) => (
                <List.Item typeof="a">
                  <a
                    href={s.path}
                    style={{ textTransform: "uppercase", color: "#235a4b" }}
                  >
                    {s.name}
                  </a>
                </List.Item>
              ))}
            </List>
          </Col>
          <Col xs={24} lg={17}>
            <div
              style={{
                backgroundImage: "url(" + image + ")",
                width: "100%",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "200px",
                marginBottom: 40
              }}
            />
            <Typography.Title>{title}</Typography.Title>
            {content}
          </Col>
        </Row>
      </div>
    </>
  );
}
