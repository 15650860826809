import { notification } from "antd";

const key = process.env.REACT_APP_MAIL_API_KEY;
export function sendMail(values, file) {
  var myHeaders = new Headers();

  myHeaders.append("Authorization", key);

  var formdata = new FormData();
  formdata.append("from", "Site internet <contact@huissierfonsorbes.fr>");
  formdata.append("to", "Etude <contact@huissierfonsorbes.fr>");
  formdata.append("to", "Thomas SIMON <thomas.s@inovec.fr>");
  formdata.append("subject", values.subject);
  formdata.append(
    "html",
    "<div style='color: #235a4b'><strong>Nom de famille:</strong></div> <div>" +
      values.lastName +
      "</div><div style='color: #235a4b'>Prénom: </div><div>" +
      values.firstName +
      "</div><div>Adresse mail: " +
      values.email +
      "</div><div>Téléphone: " +
      values.phone +
      "</div><div>Sujet: " +
      values.subject +
      "</div><div>Message: " +
      values.message +
      "</div>"
  );

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow"
  };

  fetch(
    "https://api.mailgun.net/v3/sandbox70aeda5a72e24687a617b4a2cbf64274.mailgun.org/messages",
    requestOptions
  )
    .then(() => {
      notification.open({
        message: "Votre message a bien était envoyé.",
        description: "La page va se rafraichir."
      });
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    })
    .catch(() => {
      notification.open({
        message: "Echec de l'envoi"
      });
    });
}
