import { Button, Col, Divider, Row, Typography } from "antd";

export default function Legal() {
  return (
    <>
      <div style={{ padding: "20px 20%" }}>
        <Button type="text" block>
          Retour
        </Button>
        <Row gutter={80}>
          <Col span={8}>
            <Divider orientation="left">Table des matiéres</Divider>
            <Button
              block
              style={{ textAlign: "left", marginBottom: 10 }}
              href="#definition"
            >
              Définitons
            </Button>
            <Button block style={{ textAlign: "left", marginBottom: 10 }}>
              1. Présentation du site internet
            </Button>
          </Col>
          <Col span={16}>
            <Divider orientation="left">SCP Bedry</Divider>
            <Typography>
              SCP au capital de 121 959,21 € RCS Toulouse D 330 872 524
              Activités juridiques (6910Z) N° TVA : FR94330872524 Siège social :
              1700 RTE DE TARBES 31470 FONSORBES Dirigeant : Benoit BEDRY.
            </Typography>
            <Divider orientation="left" id="definition">
              Définitons
            </Divider>
            <Typography style={{ textAlign: "justify" }}>
              Client : tout professionnel ou personne physique capable au sens
              des articles 1123 et suivants du Code civil, ou personne morale,
              qui visite le Site objet des présentes conditions générales.
              Prestations et Services : https://www.huissierfonsorbes.fr met à
              disposition des Clients : Contenu : Ensemble des éléments
              constituants l’information présente sur le Site, notamment textes
              – images – vidéos. Informations clients : Ci après dénommé «
              Information (s) » qui correspondent à l’ensemble des données
              personnelles susceptibles d’être détenues par
              https://www.huissierfonsorbes.fr pour la gestion de votre compte,
              de la gestion de la relation client et à des fins d’analyses et de
              statistiques. Utilisateur : Internaute se connectant, utilisant le
              site susnommé. Informations personnelles : « Les informations qui
              permettent, sous quelque forme que ce soit, directement ou non,
              l'identification des personnes physiques auxquelles elles
              s'appliquent » (article 4 de la loi n° 78-17 du 6 janvier 1978).
              Les termes « données à caractère personnel », « personne concernée
              », « sous traitant » et « données sensibles » ont le sens défini
              par le Règlement Général sur la Protection des Données (RGPD : n°
              2016-679)
            </Typography>
            <Divider orientation="left">
              1. Présentation du site internet.
            </Divider>
            <Typography style={{ textAlign: "justify" }}>
              En vertu de l'article 6 de la loi n° 2004-575 du 21 juin 2004 pour
              la confiance dans l'économie numérique, il est précisé aux
              utilisateurs du site internet https://www.huissierfonsorbes.fr
              l'identité des différents intervenants dans le cadre de sa
              réalisation et de son suivi: Propriétaire : SCP SCP BENOIT BEDRY –
              1700 RTE DE TARBES 31470 FONSORBES Responsable publication : SCP
              BEDRY – contact@huissierfonsorbes.fr Le responsable publication
              est une personne physique ou une personne morale. Webmaster :
              inovec – support@inovec.fr Hébergeur : ovh – 2 rue Kellermann
              59100 Roubaix 1007 Délégué à la protection des données : Benoit
              BEDRY – contact@scp-bedry.fr Ce modèle de mentions légales est
              proposé par le générateur gratuit offert par Orson.io
            </Typography>
          </Col>
        </Row>
      </div>
    </>
  );
}
