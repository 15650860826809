import { ArrowRightOutlined, PlusCircleFilled } from "@ant-design/icons";
import { Button, Card, Col, Image, Row, Space, Typography } from "antd";
import bg from "../assets/bgHome.png";
import ReactAnime from "react-animejs";
import { useEffect, useRef, useState } from "react";
import Map, { Marker, ScaleControl } from "react-map-gl";
import Icon from "@ant-design/icons/lib/components/AntdIcon";

const mapBoxToken = process.env.REACT_APP_MAPBOX_APIKEY;

export default function Home(props) {
  return (
    <>
      <div
        style={{
          backgroundImage: "url(" + bg + ")",
          padding: "40px 7%",
          backgroundPosition: "center",
          backgroundSize: "cover"
        }}
      >
        <Row gutter={40}>
          <Col
            xs={24}
            sm={24}
            md={24}
            lg={10}
            xl={10}
            style={{ padding: "40px 0px" }}
          >
            <Typography.Title
              level={props.width < 800 ? 2 : 1}
              style={{
                textAlign: props.width < 800 ? "center" : "left",
                color: "white",
                fontWeight: 300
              }}
            >
              Huissier de justice
            </Typography.Title>
            <Typography
              style={{
                color: "white",
                fontSize: props.width < 800 ? 20 : 24,
                textAlign: props.width < 800 ? "center" : "justify",
                fontWeight: 300
              }}
            >
              Le SCP BEDRY, étude d’Huissier de Justice située sur la commune de
              FONSORBES (31470) en HAUTE-GARONNE, fait preuve d’une polyvalence
              pour accompagner particuliers comme entreprises (constat,
              recouvrement, médiation, signification, etc.).
            </Typography>

            <Button
              id="btncntct"
              style={{
                height: "fit-content",
                textAlign: "left",
                padding: "14px",
                marginTop: 30,
                borderRadius: 0,
                border: "none",
                backgroundColor: "#ecfeb7",
                width: props.width < 800 ? "100%" : "auto"
              }}
            >
              <Typography style={{ color: "gray", fontSize: 12 }}>
                Vous souhaitez en savoir plus
              </Typography>
              <Typography style={{ fontSize: 16 }}>
                Contactez Nous
                <ArrowRightOutlined
                  id="arrow"
                  style={{
                    marginLeft: 10
                  }}
                />
              </Typography>
            </Button>
          </Col>
          <Col xs={24} sm={24} md={24} lg={14} xl={14}>
            <div
              style={{
                backgroundImage:
                  "url(https://images.unsplash.com/photo-1505664194779-8beaceb93744?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80)",
                backgroundSize: "cover",
                backgroundPosition: "center",
                height: "480px",
                borderRadius: 6
              }}
            />
          </Col>
        </Row>
      </div>
      <Space style={{ padding: "20px 7%" }} size={20}>
        <div>
          <Image
            src="https://commissaire-justice.fr/wp-content/uploads/2019/02/LOGO_CNCJ_header-retina.png"
            preview={false}
            width="200px"
          />
        </div>
        <div>
          <div>
            <Typography.Title style={{ color: "black" }}>
              Domaines d'Expertise
            </Typography.Title>
          </div>
        </div>
      </Space>

      <div style={{ padding: "0px 7%", paddingBottom: "80px" }}>
        <Row gutter={[40, 40]}>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 3} xl={24 / 3}>
            <div
              className="serviceCard"
              style={{
                padding: "30px",
                borderRadius: 10
              }}
            >
              <Typography.Title level={3}>CONSTAT</Typography.Title>
              <Typography>
                Le but du constat est de prouver des faits litigieux ou de se
                constituer une preuve avant tout litige. L’intervention d’un
                huissier de justice ou d’un clerc ...
              </Typography>
              <Button className="bntCardService" size="large" type="text">
                En savoir plus
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 3} xl={24 / 3}>
            <div
              className="serviceCard"
              style={{
                padding: "30px",
                borderRadius: 10
              }}
            >
              <Typography.Title level={3}>
                CONSULTATION JURIDIQUE{" "}
              </Typography.Title>
              <Typography>
                Notre expérience nous permet de répondre au mieux à toute vos
                demandes notamment lorsque celles-ci prennent la forme d’une
                consultation juridique ...
              </Typography>
              <Button className="bntCardService" size="large" type="text">
                En savoir plus
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 3} xl={24 / 3}>
            <div
              className="serviceCard"
              style={{
                padding: "30px",
                borderRadius: 10
              }}
            >
              <Typography.Title level={3}>MEDIATION </Typography.Title>
              <Typography>
                Nous intervenons à ce titre en tant que tiers neutre et
                impartial le but étant de rapprocher les parties afin de trouver
                une solution convenant aux deux parties ...
              </Typography>
              <Button className="bntCardService" size="large" type="text">
                En savoir plus
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 3} xl={24 / 3}>
            <div
              className="serviceCard"
              style={{
                padding: "30px",
                borderRadius: 10
              }}
            >
              <Typography.Title level={3}>
                RECOUVREMENT AMIABLE
              </Typography.Title>
              <Typography>
                Sur le recouvrement amiable : le but étant d’obtenir le paiement
                d’une créance sans l’intervention d’une décision de justice ...
              </Typography>
              <Button className="bntCardService" size="large" type="text">
                En savoir plus
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 3} xl={24 / 3}>
            <div
              className="serviceCard"
              style={{
                padding: "30px",
                borderRadius: 10
              }}
            >
              <Typography.Title level={3}>
                SIGNIFICATION D’ACTES{" "}
              </Typography.Title>
              <Typography>
                La signification par Huissier de justice permet de notifier à
                toute personne un acte (décision de justice, assignation,
                lettre, etc.) ...
              </Typography>
              <Button className="bntCardService" size="large" type="text">
                En savoir plus
              </Button>
            </div>
          </Col>
          <Col xs={24} sm={24} md={24 / 2} lg={24 / 3} xl={24 / 3}>
            <div
              className="serviceCard"
              style={{
                padding: "30px",
                borderRadius: 10
              }}
            >
              <Typography.Title level={3}>REDACTION D’ACTES</Typography.Title>
              <Typography>
                Nous pouvons vous accompagner pour la rédaction de vos actes
                sous seing-privé tels qu’un bail d’habitation ou encore un bail
                commercial ...
              </Typography>
              <Button className="bntCardService" size="large" type="text">
                En savoir plus
              </Button>
            </div>
          </Col>
        </Row>
      </div>
      <div style={{ userSelect: "none" }}>
        <Map
          initialViewState={{
            longitude: 1.22517,
            latitude: 43.53311,
            zoom: 13,
            pitch: 85
          }}
          style={{ width: "100vw", height: 600, display: "block" }}
          mapStyle="mapbox://styles/tsinovec/cl3jzemtm000615qn4hgw7fcs"
          mapboxAccessToken={mapBoxToken}
          dragPan={true}
          scrollZoom={false}
          doubleClickZoom={false}
        ></Map>
      </div>
    </>
  );
}
