import { MailOutlined, PhoneOutlined, ShopOutlined } from "@ant-design/icons";
import { Button, Col, Layout, Row, Space } from "antd";

export default function Footer() {
  return (
    <>
      <div style={{ padding: "40px 7%", backgroundColor: "#ecfeb7" }}>
        <Row gutter={40}>
          <Col xs={24} sm={24 / 2} md={24 / 3}>
            <h3>SCP BEDRY</h3>
            <p style={{ textAlign: "justify" }}>
              Le SCP BEDRY, étude d’Huissier de Justice située sur la commune de
              FONSORBES (31470) en HAUTE-GARONNE, fait preuve d’une polyvalence
              pour accompagner particuliers comme entreprises (constat,
              recouvrement, médiation, signification, etc.).
            </p>
          </Col>
          <Col xs={24} sm={24 / 2} md={24 / 3}>
            <h3>Plan du site</h3>
            <div>
              <a href="/" style={{ textDecoration: "none", color: "black" }}>
                Accueil
              </a>
            </div>
            <div>
              <a
                href="/about"
                style={{ textDecoration: "none", color: "black" }}
              >
                A propos
              </a>
            </div>
            <div>
              <a
                href="/services"
                style={{ textDecoration: "none", color: "black" }}
              >
                Services
              </a>
            </div>
            <div>
              <a
                href="/contact"
                style={{ textDecoration: "none", color: "black" }}
              >
                Contact
              </a>
            </div>
            <div>
              <a
                href="/legal"
                style={{ textDecoration: "none", color: "black" }}
              >
                Mentions légales
              </a>
            </div>
          </Col>
          <Col xs={24} sm={24 / 2} md={24 / 3}>
            <h3>Contactez nous</h3>
            <Space direction="vertical">
              <Button
                block
                style={{ height: "fit-content", textAlign: "left" }}
                type="text"
              >
                <Space direction="horizontal">
                  <div>
                    <PhoneOutlined />
                  </div>
                  <div>05 61 91 88 53</div>
                </Space>
              </Button>
              <Button
                href="mailto:contact@huissierfonsorbes.fr"
                type="text"
                block
                style={{ height: "fit-content", textAlign: "left" }}
              >
                <Space direction="horizontal">
                  <div>
                    <MailOutlined />
                  </div>
                  <div>contact@huissierfonsorbes.fr</div>
                </Space>
              </Button>
              <Button
                target="_blank"
                href="https://www.google.com/maps/place/B%C3%A9dry+Beno%C3%AEt/@43.5325842,1.2249934,15z/data=!4m5!3m4!1s0x0:0xd74e666cc906e688!8m2!3d43.5326285!4d1.2249599"
                type="text"
                style={{ height: "fit-content", textAlign: "left" }}
                block
              >
                <Space direction="horizontal">
                  <div>
                    <ShopOutlined />
                  </div>
                  <div>
                    1700 Rte de Tarbes
                    <br />
                    31470 Fonsorbes
                  </div>
                </Space>
              </Button>
            </Space>
          </Col>
        </Row>
      </div>
    </>
  );
}
