import { Button, ConfigProvider, Divider } from "antd";
import { useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Footer from "./components/footer";
import Navigation from "./components/header";
import { services } from "./data/services-d";
import About from "./pages/about";
import Contact from "./pages/contact";
import Home from "./pages/home";
import Legal from "./pages/legal";
import Services from "./pages/services";

ConfigProvider.config({
  theme: {
    primaryColor: "#235a4b"
  }
});

export default function App() {
  const [pageWidth, setPageWidth] = useState(document.body.offsetWidth);

  window.addEventListener("resize", () =>
    setPageWidth(document.body.offsetWidth)
  );
  return (
    <>
      <Navigation width={pageWidth} />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home width={pageWidth} />} />
          <Route path="contact" element={<Contact />} />
          <Route path="about" element={<About />} />
          <Route path="legal" element={<Legal />} />
          {services.map((s) => (
            <Route
              path={s.path}
              element={
                <Services title={s.name} content={s.content} image={s.image} />
              }
            />
          ))}
        </Routes>
      </BrowserRouter>
      <Footer />
    </>
  );
}
